<template>
  <div class="kyc">
    <AppTopbar title="KYC Information" hideSearchNotif></AppTopbar>
    <KYCInformation />
  </div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import KYCInformation from "@/components/KYCInformation.vue";

export default {
  name: "KnowYourCustomer",
  components: {
    AppTopbar,
    KYCInformation,
  },
};
</script>

<style></style>
