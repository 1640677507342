<template>
	<div class="container-fluid pt-50 pt-md-80 pb-80 pb-md-0">
		<div class="kycForm">
			<b-form @submit="kycformSubmit">
				<div class="card card__kyc mt-24 mb-20">
					<div class="row">
						<div class="col">
							<span class="d-block fs18 lh28 fw500 mb-14">
								Company Information
							</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="mw528">
								<b-form-group
								id="company-type-field"
								label-for="company-type"
								>
								<multiselect
								id="options.id"
								v-model="form.sellerCompanyType"
								placeholder="Company Type (required)"
								:options="options"
								:searchable="false"
								track-by="company" 
								label="company"
								:allow-empty="false"
								:show-labels="false"
								>
								<!-- <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template> -->
							</multiselect>
						</b-form-group>
					</div>
				</div>
				<div class="col-md-6">
					<div class="mw528">
						<b-form-group
						id="company-name-field"
						label-for="company-name"
						:description="form.sellerCompanyType.id == 6 ? 'NOTE : Invoice will be raised with this Legal Name' : 'NOTE : Invoice will be raised with this Legal Name. Legal Name should match with GST Registered Trade Name'"
						>
						<b-form-input
						required
						@blur.native="disallowSpace(form.sellerCompanyName,'company')"
						id="company-name"
						v-model="form.sellerCompanyName"
						placeholder="Legal Name (required)"
						></b-form-input>
					</b-form-group>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<span class="d-block fs18 lh28 fw500 pt-24 mb-14">
					Contact Information
				</span>
			</div>
		</div>
		<div class="row">
	

				<div class="col-md-6">
						<div class="mw528">
							<b-form-group
							id="contact1-field"
							label-for="contact1"
							>
							<b-form-input
							required
							id="contact1"
							:formatter="maxten"
							maxlength="10"
		                  	minlength="10"
							type="text"
							pattern="[6-9]{1}[0-9]{9}"
							v-model="form.sellerContactNo"
							@keyup="numberValidation($event.target.value,'contact1')"
							oninvalid="this.setCustomValidity('Enter a Valid Mobile Number')"
		                  	oninput="this.setCustomValidity('')"
							placeholder="Primary Contact Number (required)"
							:state="isNumber"
							></b-form-input>
						</b-form-group>
					</div>
				</div>

				<div class="col-md-6">
						<div class="mw528">
							<b-form-group
							id="contact2-field"
							label-for="contact2"
							>
							<b-form-input
							id="contact2"
							:formatter="maxten"
							type="number"
							v-model="form.sellerContactNo2"
							@keyup="numberValidation($event.target.value,'contact2')"
							placeholder="Secondary Contact Number (optional)"
							:state="isNumber2"
							></b-form-input>
						</b-form-group>
					</div>
				</div>
		</div>

<div class="row pt-20 pt-md-40">
	<div class="col-md-6">
		<div class="mw528">
			<span class="d-block fs18 lh28 fw500 mb-14"> Identity Verification 
				<img @click="editAadhaar()" v-if="aadhaarVerified || voterverified || dlverified" class="ml-10 my-auto editImage" src="@/assets/svg/edit.svg">
			</span>
			<div class="row">
				<div class="col-md-4">
					<b-form-group label-for="type" >
						<multiselect
						id="type"
						:disabled="aadhaarVerified || voterverified || dlverified"
						title="Mode"
						v-model="idType"
						:options="computedidTypes"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
						class="mb-17"
						></multiselect>
					</b-form-group>
				</div>
				<div class="col-md-8" v-if="idType == 'Aadhaar'">
					<b-form-group
					id="company-gstin-field"
					label-for="company-aadhaar"
					>
					<b-form-input
					required
					type="text"
					pattern="[0-9]{12}"
					oninvalid="this.setCustomValidity('Enter valid AADHAAR number.')"
					oninput="this.setCustomValidity('')"
					:formatter="numbers"
					maxlength="12"
					minlength="12"
					:disabled="aadhaarVerified"
					id="company-aadhaar"
					v-model="form.adhaar"
					placeholder="AADHAAR Number (required)"
					></b-form-input>
						<div class="input-group-append">
							<div class="input-group-text" :style="aadhaarVerified ? 'background-color:#e3e7ee' : '' ">
								<b-button disabled size="xxs" class="verified" v-if="aadhaarVerified">Verified</b-button>
								<b-button v-else size="xxs" @click="verifyAadhaar(form.adhaar)" variant="tertiary"> 
									<b-spinner v-if="spinaadhaar"></b-spinner>
									<span v-else>Verify </span> 
								</b-button>
							</div>
						</div>
					</b-form-group>
				</div>

				<div class="col-md-8" v-if="idType == 'Voter ID'">
					<b-form-group
					id="company-gstin-field"
					label-for="company-aadhaar"
					>
					<b-form-input
					required
					type="text"
					pattern="[a-zA-Z0-9]"
					oninvalid="this.setCustomValidity('Enter valid Voter Id number.')"
					oninput="this.setCustomValidity('')"
					maxlength="10"
					minlength="10"
					:disabled="voterverified"
					id="company-aadhaar"
					v-model="form.voterId"
					placeholder="Voter ID Number (required)"
					></b-form-input>
						<div class="input-group-append">
							<div class="input-group-text" :style="voterverified ? 'background-color:#e3e7ee' : '' ">
								<b-button disabled size="xxs" class="verified" v-if="voterverified">Verified</b-button>
								<b-button v-else size="xxs" @click="verifyVoter(form.voterId)" variant="tertiary"> 
									<b-spinner v-if="spinaadhaar"></b-spinner>
									<span v-else>Verify </span> 
								</b-button>
							</div>
						</div>
					</b-form-group>
				</div>

				<div class="col-md-8 row pr-0" v-if="idType == 'Driving License'">
					<div class="col-md-12 pr-0" v-if="!dlverified">
						<date-picker
				            prefix-class="xmx"
				            v-model="dobDl"
				            id="dp"
				            title="Date of Birth"
				            value-type="format"
				            class="mb-17"
				            format="YYYY-MM-DD"
				            :disabled-date="(date) => date > new Date()"
				            placeholder="Date of Birth"
				            :editable="false"
				          ></date-picker>
					</div>
					<div class="col-md-12">
						<b-form-group
						id="company-gstin-field"
						label-for="company-aadhaar"
						>
						<b-form-input
						required
						type="text"
						maxlength="16"
						minlength="10"
						:disabled="dlverified"
						id="company-aadhaar"
						v-model="form.drivinglicense"
						placeholder="Driving License Number (required) "
						></b-form-input>
							<div class="input-group-append">
								<div class="input-group-text" :style="dlverified ? 'background-color:#e3e7ee' : '' ">
									<b-button disabled size="xxs" class="verified" v-if="dlverified">Verified</b-button>
									<b-button v-else size="xxs" @click="verifyDL(form.drivinglicense,dobDl)" variant="tertiary"> 
										<b-spinner v-if="spinaadhaar"></b-spinner>
										<span v-else>Verify </span> 
									</b-button>
								</div>
							</div>
						</b-form-group>
					</div>
					
				</div>
			</div>

			<div class="d-flex" v-if="noLinked">
				<div class="col-md-4"></div>
				<div class="col-md-10 row" style="max-width: 75.33333%;">
				<!-- <div class="row col" > -->
					<div class="col-md-4 pt-8">
						<b-form-group id="otp-field" label-for="otp">
							<b-form-input
							type="text"
							id="otp"
							v-model="otp"
							placeholder="Enter OTP"
							autofocus
							required
							:formatter="maxsix"
							></b-form-input>
						</b-form-group>
					</div>
					<div class="pt-5 ml-5">
						<b-button variant="primary" @click="verifyOtp(otp)" size="lg" class="mr-10"> 
							<b-spinner v-if="spinotp"></b-spinner>
							<span v-else>{{ btns.verify }}</span> 
						</b-button>
					</div>
					<div class="pt-5">
						<b-button variant="tertiary" @click="resendOtp(form.adhaar)" :disabled="!resend" size="lg" class="mr-5"> 
							<b-spinner v-if="spinresend"></b-spinner>
							<span v-else>Resend OTP </span> 
						</b-button>
						<!-- <span class="fs14" v-if="countdown > 0">{{countdown}}</span> -->
						<!-- <b-link class="fs14" @click="showUpload">Upload Aadhaar Manually</b-link> -->
					</div>

					<div class="col-md-12 mt-10 ml-5" v-if="countdown > 0">
						<p class="fs16" >You can send an otp again in {{countdown}} {{countdown < 10 ? 'second' : 'seconds'}}</p>
					</div>

					<div class="col-md-12 mt-10 ml-5">
						<span class="fs12 text-gray-600">By entering OTP, I hereby give my voluntary consent for authenticating myself through Aadhaar based Authentication system as permitted under applicable laws. </span>
					</div>
					
					<div class="col-md-12 pt-20 pt-md-20 ml-5">
						<b-link class="fs14" @click="showUpload">Upload Aadhaar Manually</b-link>
					</div>
					
				</div>
			</div>
			

			<div class="d-flex">
				<div class="col-md-4"></div>
				<div class="col-md-10 row" v-if="noNotLinked" style="max-width: 76.33333%;">
				<!-- <div class="row"> -->
					<p class="d-block fs14 ml-5 lh20 pt-5 pb-10 text-gray-600">
						Max file size per document is 5MB. Supported formats are
						JPG, PNG, JPEG.
					</p>
					<div class="col-md-12 pt-5 d-flex justify-content-center">
							<div class="col-md-6">
								<b-form-file
								plain
								accept=".jpeg, .jpg, .png"
								ref="aadhaar-front-input"
								v-model="form.adhaarfrontImg"
								class="d-none"
								@input="selectImages(form.adhaarfrontImg,'front')"
								></b-form-file>
								<div class="w-sm-100">
									<b-button
									@click="popInput('aadhaar-front-input')"
									variant="primary"
									class="w-sm-100 mr-0 mr-md-8"
									:title="form.adhaarfrontImg ? form.adhaarfrontImg.name :''"
									>
									<img class="mr-5 selectImage" src="@/assets/svg/image.svg">
									{{form.adhaarfrontImg ? imageEllipsize(form.adhaarfrontImg.name) :'Select Front Side'}}
									</b-button>
								</div>
								<!-- <div class="col mt-10" style="overflow:hidden" v-if="form.adhaarfrontImg != null">
									<span class="frontClass"> {{form.adhaarfrontImg ? form.adhaarfrontImg.name :''}} </span> Selected 
									<i
									class="icon-disclaimer text-gray-400 fs12 ml-10 my-auto"
									v-b-tooltip.hover.right
									title="File Name"
									:title="form.adhaarfrontImg ? form.adhaarfrontImg.name :''"
									></i>
								</div> -->
							</div>
							<div class="col-md-6">
								<b-form-file
								plain
								accept=".jpeg, .jpg, .png"
								ref="aadhaar-back-input"
								v-model="form.adhaarbackImg"
								class="d-none"
								@input="selectImages(form.adhaarbackImg,'back')"
								></b-form-file>
								<div class="w-sm-100">
									<b-button
									@click="popInput('aadhaar-back-input')"
									variant="primary"
									class="w-sm-100 mr-0 mr-md-8"
									:title="form.adhaarbackImg ? form.adhaarbackImg.name :''"
									>
									<img class="mr-5 selectImage" src="@/assets/svg/image.svg">
									{{form.adhaarbackImg ? imageEllipsize(form.adhaarbackImg.name) :'Select Back Side'}}
									</b-button>
							</div>
						</div>
					</div>

					<div class="col-md-12 ml-10 mt-10 row" v-if="form.adhaarfrontImg != null && form.adhaarbackImg != null">
						<b-button  @click="submitOCR(form.adhaarfrontImg, form.adhaarbackImg, form.adhaar)" variant="primary" class="w-sm-100 mt-8 mt-md-0">
							<b-spinner v-if="spinOCR"></b-spinner>
							<span v-else>Upload</span>
						</b-button>
					</div>
				</div>
			</div>
			
</div>
</div>

<div class="col-md-6">
	<div class="mw528">
		<span class="d-block fs18 lh28 fw500 mb-14"> GSTIN
			<img v-if="gstVerified" @click="gstVerified = null" class="ml-10 my-auto editImage" src="@/assets/svg/edit.svg">
		</span>

		<div class="row">
			<div class="col">
				<b-form-group
				id="company-gstin-field"
				label-for="company-gstin"
				>
					<b-form-input
					:required="form.sellerCompanyType.id == 6 ? false : true"
					type="text"
					maxlength="15"
					id="company-gstin"
					@input="enterGST(form.gstno)"
					v-model="form.gstno"
					:disabled="gstVerified"
					:placeholder="form.sellerCompanyType.id == 6 ? `Company's GSTIN (optional)` : `Company's GSTIN (required)` "
					></b-form-input>
					<div  class="input-group-append">
						<div class="input-group-text" :style="gstVerified ? 'background-color:#e3e7ee' : '' ">
							<!-- <b-button disabled size="xxs" class="verified" v-if="gstVerified">Verified</b-button> -->
							<b-button v-if="!gstVerified" size="xxs" @click="verifyGST(form.gstno)" variant="tertiary">  
								<b-spinner v-if="spingst"></b-spinner>
								<span v-else>Verify</span>
							</b-button>
						</div>
					</div>
				</b-form-group>
			</div>
		</div>
		<div class="row" v-if="this.form.sellerCompanyType && this.form.sellerCompanyType.id == 6 && this.form.gstno.length">
			<div class="col">
				<p class="d-block fs14 ml-5 lh20 text-red" style="font-weight:600;">
					NOTE : Kindly change your Company Type to Sole Proprietorship/Firm
				</p>
			</div> 
		</div>
	</div>
</div>

<!--  -->

</div>
<div class="row pt-20 pt-md-40">
	<div class="col-md-6">
		<div class="mw528">
			<span class="d-block fs18 lh28 fw500 mb-14"> Billing Address
				<i
				class="icon-disclaimer text-gray-400 fs12 ml-10"
				v-b-tooltip.hover.right
				title="You can add your Pickup and Billing Address later"
				></i>
			</span>
			<div class="row">
				<div class="col">
					<b-form-group
					id="company-tan-field"
					label=""
					label-for="company-tan"
					:description="form.sellerCompanyType.id == 6 && this.idType == 'Aadhaar' ? `This would be automatically captured by your Aadhaar` : form.sellerCompanyType.id == 6 && this.idType == 'Driving License' ? `This would be automatically captured by your Driving License` : `This would be automatically captured by your GSTIN`"
					>
					<b-form-textarea
					required
					id="company-billing"
					placeholder="Your Billing Address"
					rows="1"
					:disabled="addressDisable"
					max-rows="6"
					v-model="form.address"
					></b-form-textarea>
					</b-form-group>
				</div>
			</div>
			<div v-if="!addressDisable" class="row col">
				<div class="col-md-6">
					<b-form-input
					required
					id="company-city"
					type="text"
					v-model="form.city"
					placeholder="City (required)"
					></b-form-input>
				</div>
				<div class="col-md-6">
					<b-form-input
					required
					id="company-pin"
					type="text"
					v-model="form.pincode"
					placeholder="Pincode (required)"
					></b-form-input>
				</div>
			</div>
	</div>
</div>

<div class="col-md-6">
	<div class="mw528">
		<span class="d-block fs18 lh28 fw500 mb-14"> PAN 
			<img v-if="form.sellerCompanyType.id == 6 && panVerified" @click="panVerified = null" class="ml-10 my-auto editImage" src="@/assets/svg/edit.svg">
		</span>
		<div class="row">
			<div class="col">
				<b-form-group
				id="company-pan-field"
				label-for="company-pan"
				>
				<b-form-input
				:disabled="form.sellerCompanyType.id == 6 && (panVerified == null) ? false : panVerified == true ? true : true"
				required
				maxlength="10"
				id="company-pan"
				type="text"
				v-model="form.panNo"
				placeholder="Company's PAN (required)"
				></b-form-input>
				<div class="input-group-append">
					<div class="input-group-text cin-text" :style="(form.sellerCompanyType.id == 6 && (panVerified == null) ? false : panVerified == true ? true : true) ? 'background-color:#e3e7ee' : '' ">
							<!-- <b-button disabled size="xxs" class="verified" v-if="form.sellerCompanyType.id == 6 && (panVerified == null) ? false : 
							form.sellerCompanyType.id == 6 && panVerified ? true :
							panVerified == true ? true 
							: false">Verified</b-button> -->

							<b-button v-if="form.sellerCompanyType.id == 6 && panVerified == null" size="xxs" @click="verifyPAN(form.panNo)" variant="tertiary">  
								<b-spinner v-if="spinpan"></b-spinner>
								<span v-else>Verify</span>
							</b-button>

					</div>
				</div>
			</b-form-group>
		</div>
	</div>
</div>
</div>

</div>
<div class="row pt-20 pt-md-40 pb-20">


	<div class="col-md-6" v-if="form.sellerCompanyType.id == 1 || form.sellerCompanyType.id == 3 || form.sellerCompanyType.id == 5 || form.sellerCompanyType.id == 8">
		<div class="mw528">
			<span class="d-block fs18 lh28 fw500 mb-14"> Certificate of Incorporation 
				<img v-if="coiVerified" @click="coiVerified = null" class="ml-10 my-auto editImage" src="@/assets/svg/edit.svg">
			</span>

			<div class="row">
				<div class="col">
					<b-form-group
					id="company-tan-field"
					label=""
					label-for="company-tan"
					>
					<b-form-input
					required
					maxlength="21"
					minlength="7"
					placeholder="Company's CIN (required)"
					v-model="form.coi"
					pattern="[a-zA-Z0-9]+" oninvalid="this.setCustomValidity('Please Enter a Valid COI Number')"
              		oninput="this.setCustomValidity('')"
              		:disabled="coiVerified"
					></b-form-input>
					<div class="input-group-append">
					<div class="input-group-text cin-text" :style="coiVerified ? 'background-color:#e3e7ee' : '' ">
							<!-- <b-button disabled size="xxs" class="verified" v-if="coiVerified">Verified</b-button> -->

							<b-button v-if="!coiVerified" size="xxs" @click="verifyCIN(form.coi)" variant="tertiary">  
								<b-spinner v-if="spincoi"></b-spinner>
								<span v-else>Verify</span>
							</b-button>

					</div>
				</div>
				</b-form-group>
			</div>
		</div>
	</div>
</div>


<div class="col-md-6">
		<div class="mw528">
			<span class="d-block fs18 lh28 fw500 mb-14"> Full Name
			</span>
				<b-form-group
				id="seller-name"
				label-for="seller-name-field"
				>
				<b-form-input
				  required
				  id="seller-name-field"
	              v-model="form.name"
	              oninvalid="this.setCustomValidity('Please Enter full name')"
	              oninput="this.setCustomValidity('')"
	              pattern=".* .*"
	              placeholder="Your complete name (required)"
	              trim
	              maxlength="50"
	              @blur.native="disallowSpace(form.name,'name')"
	              type="text"
				></b-form-input>
			</b-form-group>
		</div>
	</div>

<!-- Photo Identification Commented for the moment -->
<!-- <div class="col-md-6">
	<span class="d-block fs18 lh28 fw500 mb-14"> Photo Identification </span>
	<div class="mw528">
		<div class="row pt-5 d-flex">
			<b-form-file
			plain
			accept=".jpeg, .jpg, .png"
			ref="face-file-input"
			v-model="form.faceImg"
			class="d-none"
			@input="selectImages(form.faceImg,'face')"
			></b-form-file>
			<div class="col-md-4" >
				<b-button
				@click="openWebcamModal"
				variant="primary"
				class="w-sm-100 mr-0 mr-md-8">
					<img class="mr-5 selectImage" src="@/assets/svg/camera.svg">
					Take Photo
				</b-button>
				<div v-if="selfieTaken" class="col pt-md-10 text-gray-600">File Uploaded</div>
			</div>
		<div class="col-md-2 fs20 my-auto text-gray-600"> <strong>OR</strong> </div>
		<div class="col-md-6">
			<b-button
			@click="popInput('face-file-input')"
			variant="tertiary"
			class="w-sm-100 mr-0 mr-md-8"
			>
			<img class="mr-5 selectImage" src="@/assets/svg/image.svg">
			Select Photo
		</b-button>
		<b-button
		v-if="form.faceImg != null"
		@click="clearImages('face')"
		variant="red"
		class="w-sm-100 mt-8 mt-md-0"
		>
		<i class="icon-close-variant"></i>
	</b-button>
	<div class="row pt-10 pl-10" style="overflow:hidden" v-if="form.faceImg">
		<span class="frontClass">  {{form.faceImg ? form.faceImg.name : ''}}
		</span>File Uploaded <i
		class="icon-disclaimer text-gray-400 fs12 ml-10 my-auto"
		v-b-tooltip.hover.right
		title="File Name"
		:title="form.faceImg ? form.faceImg.name : ''"
		></i>
	</div>
</div>

</div>
<div class="pt-20 my-auto">
	<p class="d-block fs14 ml-5 lh20 text-gray-600">
		Max file size per document is 5MB. Supported formats are JPG, PNG, JPEG.
	</p>
</div> 
</div>
</div> -->


</div>


	<div class="pt-10 pb-20">
		<div class="ml-0 fs16">
			<b-form-checkbox v-model="form.agreement">
				<strong><span>By clicking Submit, you agree to Shyplite's 
				<b-link target="_blank" :href="agreementUrl" class="ml-0 fs16">Agreement</b-link>, 
				<b-link target="_blank" href="https://shyplite.com/tou" class="ml-0 fs16">Terms of Service</b-link> and 
				<b-link class="ml-0 fs16" target="_blank" href="https://shyplite.com/privacy">Privacy Policy</b-link></span></strong>
			</b-form-checkbox>
		</div>
	</div>

	
	<div class="d-flex pb-20 pt-md-20">
		<b-button
		variant="primary"
		size="lg"
		:disabled="!form.agreement"
		type="submit"
		>
		<b-spinner v-if="submitting"></b-spinner>
		<span v-else>Submit</span>
	</b-button>
	<b-button to="/dashboard" variant="secondary" size="lg" class="ml-16">
		Skip
	</b-button>
</div>

</div>
</b-form>
</div>
<KYCInfoModal ref="infoModal" />
<Webcam @openModal="openWebcamModal" ref="webcam" />
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { mapState } from 'vuex'
import KYCInfoModal from "@/components/KYCInfoModal.vue";
import Webcam from "@/components/Webcam.vue";
export default {
	name: "KYCInformation",
	data() {
		return {
			webcamFlag:false,
			addressDisable: true,
			maxLength: 150,
			options: [],
			isNumber:null,
			isNumber2:null,
			pincode:null,
			webCheck:null,
			form: {
				sellerCompanyName:"",
				sellerCompanyType:"",
				sellerContactNo:"",
				sellerContactNo2:"",
				gstno: '',
				adhaar:'',
				panNo:'',
				address:'',
				agreement:true,
				payU:false,
				coi:"",
				location:{}
			},
			dobDl:null,
			selfieTaken: false,
			files: [],
			countdown:null,
			spinaadhaar:false,
			spingst:false,
			spinpan:false,
			spinotp:false,
			spincoi:false,
			spinresend:false,
			src: "",
			idType:'Aadhaar',
			idTypes:['Aadhaar','Voter ID','Driving License'],
			noLinked:false,
			noNotLinked:false,
			spinOCR:false,
			idImg: "",
			addressImg: "",
			coiImg: "",
			gstImg: "",
			value: "",
			otp:null,
			adhaarImg:"",
			faceImg:"",
			btns:{
				verify:'Verify OTP'
			},
			showCam:true,
			aadhaarVerified:null,
			gstVerified:null,
			panVerified:null,
			coiVerified:null,
			errorFlag1:false,
			errorFlag2:false,
			errorFlag4:false,
			errorFlag5:false,
			agreementUrl:'',
			resend:false,
			errorText:"",
			companyTypeList:[],
			hideCOI:false,
			uploadImages:[],
			uploadfaceImages:[],
			submitting:false,
			timerStart: false,
			voterverified:false,
			dlverified:false
		};
	},
	components: {
		Multiselect,
		KYCInfoModal,
		Webcam,
		DatePicker
	},
	created() {
		this.redirectDashboard();
	},
	async mounted() {
		await this.getcoTypes();
		await this.getKycdata();
	},
	computed:{
		computedidTypes() {
			if (this.form.sellerCompanyType.id == 6) {
				return this.idTypes.filter(i => i != "Voter ID")
			} else {
				return this.idTypes
			} 
		}
	},
	watch:{
		'form.sellerCompanyType' : function(newValue) {
			if (newValue.id == 6 && !(this.aadhaarVerified || this.voterverified || this.dlverified)) {
				this.idType = 'Aadhaar'
			}
			if (this.voterverified && newValue.id == 6) {
				this.form.sellerCompanyType = ""
				this.popToast('failed','Failed',"Voter Id verification is not allowed on Individual Company Type!! Kindly change Company Type or Verify with different Identity Verification options")
			}
		},
		idType:function(newValue) {
			if (newValue != 'Aadhaar') {
				this.noLinked = false
				this.noNotLinked = false
			}
		}
	},
	methods: {
		getGeoLocation() {
			navigator.geolocation.getCurrentPosition(pos => {
			  let obj = {
			  	latitude : pos.coords.latitude,
			  	longitude : pos.coords.longitude
			  }
		      this.form.location = Object.assign({},obj)
		    }, err => {
		    	console.log(err)
		    });
		},
		redirectDashboard() {
			if (this.$store.state.user.sellerStatus == 1 || this.$store.state.user.sellerStatus == 2) {
				this.$router.push('/dashboard')
			} else {
			}
		},
		editAadhaar() {
			this.aadhaarVerified = null;
			this.voterverified = null;
			this.dlverified = null;
			this.form.voterId = "";
			this.form.drivinglicense = "";
			this.form.adhaar = "";
		},
		async getKycdata() {
			try {
				const res = await this.axios.get('/register/kyc')
				if (res.data.status) {
					this.form.agreementID = res.data.data.agreementID
					this.agreementUrl = res.data.data.agreement
					this.aadhaarVerified = res.data.data.aadhaarIsApproved
					this.coiVerified = res.data.data.coiIsApproved
					this.form.adhaar = res.data.data.aadhaarNumber
					this.form.name = res.data.data.name
					this.gstVerified = res.data.data.gstIsApproved
					this.form.gstno = res.data.data.gstNumber
					this.panVerified = res.data.data.panIsApproved
					this.form.panNo = res.data.data.panNumber && res.data.data.panNumber.length ? res.data.data.panNumber : String(res.data.data.gstNumber).substring(2, 12);
					this.form.coi = res.data.data.coiNumber
					this.form.address = res.data.data.address
					this.form['pincode'] = res.data.data.pincode;
					this.form['city'] = res.data.data.city;
					this.voterverified = res.data.data.idType.idType == 'voterId' ? true : false
					this.dlverified = res.data.data.idType.idType == 'license' ? true : false
					this.form.voterId = res.data.data.idType.idType == 'voterId' ? res.data.data.idType.id : null
					this.form.drivinglicense = res.data.data.idType.idType == 'license' ? res.data.data.idType.id : null
					this.idType = res.data.data.idType.idType == 'voterId' ? 'Voter ID' : res.data.data.idType.idType == 'license' ? 'Driving License' : 'Aadhaar'
					this.form['addressID'] = res.data.data.addressID
					this.form.sellerCompanyName = res.data.data.sellerCompanyName
					this.form.sellerContactNo = res.data.data.sellerContactNo ? res.data.data.sellerContactNo : res.data.data.contact
					this.form.sellerContactNo2 = res.data.data.sellerContactNo2
					let type = this.options.find(item => item.id == res.data.data.sellerCompanyType)
					if (type && type.id && type.company) {
						this.form.sellerCompanyType = {
							id: type.id,
							company: type.company
						}
					}
				} else {
				}
			} catch(e) {
				this.popToast('failed','Failed','Server Error!! Please Try Again Later')
			}
		},
		async submitOCR(front, back, aadhaar) {
			this.spinOCR = true
			const obj = {}
			obj['frontAadhaar'] = front
			obj['backAadhaar'] = back
			obj['aadhaarNumber'] = aadhaar
			let fd = new FormData();
			for (const key of Object.keys(obj)) {
				fd.append(key, obj[key]);
			}
			try {
				const res = await this.axios.post('/register/aadhaarOCRVerification',fd)
				if (res.data.status) {
					this.popToast('booked','Success',res.data.message)
					this.spinOCR = false
					this.aadhaarVerified = true
					this.noNotLinked = false
					if (res.data.address && this.form.sellerCompanyType.id == 6) {
						this.form.address = ''
						this.form.address = res.data.address;
						this.form['pincode'] = res.data.pincode;
						this.form['city'] = res.data.city
						this.form['addressID'] = res.data.addressID
					}

				} else {
					this.spinOCR = false
					this.noNotLinked = true
					this.aadhaarVerified = null
					if(res.data.message == 'Backend Timed Out. Try Again.') {
						this.popToast('booked','Success', 'Aadhaar uploaded successfully.')
						this.aadhaarVerified = true
						if(this.form.sellerCompanyType.id == 6) {
							this.addressDisable = false
						}
					}else {
						this.popToast('failed','Failed',res.data.message)
					}
				}
			} catch(e) {
        // statements
        this.spinOCR = false
        this.noNotLinked = true
        this.aadhaarVerified = null
    }
},
disallowSpace(name,type) {
	if (type == 'company') {
		if (this.form.sellerCompanyName && this.form.sellerCompanyName.length) {
			this.form.sellerCompanyName = name.split(' ').filter(i => i.trim().length).join(' ');
		}
	} else {
		if (this.form.name && this.form.name.length) {
			this.form.name = name.split(' ').filter(i => i.trim().length).join(' ');
		}
	}
	 
},
async kycformSubmit(event){
	this.submitting = true
	event.preventDefault();
	let element = document.getElementById('company-aadhaar');   
	element.scrollIntoView(true);
	const newData = { ...this.form }
	newData.sellerCompanyType = newData.sellerCompanyType.id


	if (this.idType == 'Aadhaar' && this.aadhaarVerified == null) {
		this.popToast("failed","Failed","Aadhaar Verification is Mandatory");
		let element = document.getElementById('company-aadhaar');   
		element.scrollIntoView(true);
		this.submitting = false
	} else if (this.idType == 'Voter ID' && this.voterverified == null) {
		this.popToast("failed","Failed","Voter Id Verification is Mandatory");
		let element = document.getElementById('company-aadhaar');   
		element.scrollIntoView(true);
		this.submitting = false
	} else if (this.idType == 'Driving License' && this.dlverified == null){
		this.popToast("failed","Failed","Driving License Verification is Mandatory");
		let element = document.getElementById('company-aadhaar');   
		element.scrollIntoView(true);
		this.submitting = false
	} else {

	}

	if (this.gstVerified == null && this.form.sellerCompanyType.id != 6) {
		this.popToast("failed","Failed","GST Verification is Mandatory");
		let element = document.getElementById('company-gstin');    
		element.scrollIntoView(true);
		this.submitting = false
	} else if (this.panVerified == null && this.form.sellerCompanyType.id == 6) {
		this.popToast("failed","Failed","PAN verification is mandatory.");
		let element = document.getElementById('company-pan');    
		element.scrollIntoView(true);
		this.submitting = false
	}
	/**
	 * Commenting this because anyone can say to revert it back anytime.
	 */
	// else if (this.form.sellerCompanyType.id != 6 && (!this.form.gstCompanyName || this.form.gstCompanyName.toLowerCase() != this.form.sellerCompanyName.toLowerCase())) {
	// 	this.popToast("failed","Failed","Legal Name does not match with GST Legal Name.");
	// 	let element = document.getElementById('company-name');    
	// 	element.scrollIntoView(true);
	// }
	else if (this.form.gstno.length && this.form.sellerCompanyType.id == 6) {
		this.popToast("failed","Suggestion","Kindly change your Company Type to Sole Proprietorship/Firm.");
		this.submitting = false
	} else if (this.coiVerified == null && (this.form.sellerCompanyType.id == 1 || this.form.sellerCompanyType.id == 3 || this.form.sellerCompanyType.id == 5 || this.form.sellerCompanyType.id == 8)) {
		this.popToast("failed","Suggestion","COI Verification is Mandatory");
		this.submitting = false
	}
	else {
		try {
			const res = await this.axios.post('/register/kyc',newData)
			if (res.data.status) {
				this.openInfoModal();
				const user = {}
				user.registrationCompleted = true
				user.sellerStatus = res.data.sellerDetail?.status
				this.$store.commit('setUser', user)
				this.submitting = false
				if (res.data.sellerDetail?.status == 1) {
					this.$store.commit('setSellerstate', true)
				} else {
					this.$store.commit('setSellerstate', false)
				}
				// setTimeout(() => {
				// 	this.$router.push("/dashboard");
				// },10000)
			} else {
				this.popToast("failed","Failed",res.data.message || res.data.error);
				this.submitting = false
			}
		} catch(e) {
          // statements
          this.popToast("failed","Failed","Server Error!! Please Try Again Later");
          this.submitting = false
      }
  }
},
async getcoTypes() {
	try {
		const response = await this.axios.get('/register/company')
		this.options = response.data.result.companyTypeList
		this.options = this.options.filter(i => i !=='').map(function(key,value){
			return{
				id:value+1,
				company:key
			}
		})
	} catch(err) {
		console.log(err,"erro")
	}
},
numberValidation(val,con) {
	if((val || val == '') && con === "contact1") {
		if(val.match(/^[6-9]{1}[0-9]{9}$/)){
			this.isNumber = true
		}else if (val == ''){
			this.isNumber = null
		} else {
			this.isNumber = false
		}
	} else if((val || val == '') && con === "contact2") {
		if(val.match(/^[6-9]{1}[0-9]{9}$/)){
			this.isNumber2 = true
		}else if(val == ''){
			this.isNumber2 = null
		} else {
			this.isNumber2 = false
		}
	} else if ((val || val == '') && con === "pin") {
		if(val.match(/^[0-9]{6}$/)){
			this.pincode = true
		}else if(val == ''){
			this.pincode = null
		} else {
			this.pincode = false
		}
	} else {

	}
},
maxten(e){
	return String(e).substring(0, 10);
},
maxsix(e){
	return String(e).substring(0, 6);
},
checkWebsite(val) {
	var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
	var regex = new RegExp(expression);
	if(val || val == '') {
		if(val.match(regex)) {
			this.webCheck = true
		} else if(val == "") {
			this.webCheck = false
		} else {
			this.webCheck = false
		}
	}
},
async resendOtp(adhaar) {
	this.spinresend = true
	try {
		this.resend = false;
		const res = await this.axios.post('/register/aadhaarResendOTP',{adhaarNumber:adhaar})
		if (res.data.status) {
			this.spinresend = false
			this.popToast('booked','Success',res.data.message)
			this.countdown = 30
			if (this.timerStart) {
				clearInterval(this.timerStart);
			}
			this.timer();
			setTimeout(() => {
				this.resend = true
				this.otp = ''
			},30000)
		} else {
			this.spinresend = false
			this.popToast('failed','Failed',res.data.message)
			this.noLinked = false;
			this.noNotLinked = true;
		}
	} catch(e) {
        this.spinresend = false
        console.log(e);
    }
},
async verifyOtp(Otp){
	this.spinotp = true
	try {
		const res = await this.axios.post('/register/aadhaarVerifyOTP',{otp:Otp})
		if (res.data.status) {
			this.popToast('booked','Success',res.data.message)
			this.aadhaarVerified = true
			this.spinotp = false
			this.noLinked = false;
			if (res.data.address && this.form.sellerCompanyType.id == 6) {
				this.form.address = ''
				this.form.address = res.data.address;
				this.form['pincode'] = res.data.pincode;
				this.form['city'] = res.data.city
				this.form['addressID'] = res.data.addressID
			}
		} else {
			this.spinotp = false
			this.aadhaarVerified = null
			this.noLinked = true
			if(res.data.message == 'Backend Timed Out. Try Again.') {
				this.noNotLinked = true
				this.noLinked = false
				this.popToast('failed','Failed', 'We are not able to validate your Aadhaar right now. Please upload your Aadhaar manually.')
				// if(this.form.sellerCompanyType.id == 6) {
				// 	this.addressDisable = false
				// }
			}else {
				this.noNotLinked = true
				this.noLinked = false
				this.popToast('failed','Failed',res.data.message)
			}
		}
	} catch(e) {
        // statements
        this.spinotp = false
        this.aadhaarVerified = null
        console.log(e);
    }
},
showUpload() {
	this.noLinked = !this.noLinked
	this.noNotLinked = !this.noNotLinked
},
timer() {
	this.timerStart = setInterval(() => {
		if(this.countdown>0) {
			this.countdown--
		}
	}, 1000);
},
async verifyVoter(voterid) {
	this.spinaadhaar = true;
	if (voterid == null || voterid.length < 10) {
		this.popToast('failed','Failed',"Please enter a Valid Voter Id Number")
		this.spinaadhaar = false
	} else if (!(this.form.sellerCompanyType && this.form.sellerCompanyType.id)) {
		this.popToast('failed','Failed',"Please Select a Company Type")
		this.spinaadhaar = false
	} else {
		try {
			const res = await this.axios.post('/register/voterIDverification',{id_number:voterid})
			if (res.data.status) {
				this.popToast('booked','Success',res.data.message)
				this.voterverified = true
			} else {
				this.popToast('failed','Failed',res.data.message)
				this.voterverified = false
			}
		} catch(e) {
			this.popToast('failed','Failed',res.data.e)
			this.voterverified = false		
		}
	}
	this.spinaadhaar = false
},
async verifyDL(dl,date) {
	this.spinaadhaar = true;
	if (dl == null || dl.length < 10) {
		this.popToast('failed','Failed',"Please enter a Valid Driving License Number")
		this.spinaadhaar = false
	} else if (!(this.form.sellerCompanyType && this.form.sellerCompanyType.id)) {
		this.popToast('failed','Failed',"Please Select a Company Type")
		this.spinaadhaar = false
	} else if (date == null) {
		this.popToast('failed','Failed',"Please enter a valid Date of Birth")
		this.spinaadhaar = false
	}
	 else {
		try {
			const res = await this.axios.post('/register/licenseVerification',{id_number:dl,dob:date})
			if (res.data.status) {
				this.popToast('booked','Success',res.data.message)
				this.dlverified = true
				if (res.data.pincode && this.form.sellerCompanyType.id == 6) {
					this.form.address = ''
					this.form.address = res.data.body.data.permanent_address;
					this.form['pincode'] = res.data.pincode;
					this.form['city'] = res.data.city
					this.form['addressID'] = res.data.addressID
				}
			} else {
				this.popToast('failed','Failed',res.data.message)
				this.dlverified = false
			}
		} catch(e) {
			this.popToast('failed','Failed',res.data.e)
			this.dlverified = false
		}
	}
	this.spinaadhaar = false
},
async verifyAadhaar(aadhaar) {
	this.spinaadhaar = true;
	this.otp = ''
	if (aadhaar.length < 12) {
		this.popToast('failed','Failed',"Please enter a Valid Aadhaar Number")
		this.spinaadhaar = false
	} else if (!(this.form.sellerCompanyType && this.form.sellerCompanyType.id)) {
		this.popToast('failed','Failed',"Please Select a Company Type")
		this.spinaadhaar = false
	} 
	else {
		try {
			this.resend = false;
			const res = await this.axios.post('/register/aadhaarValidation',{adhaarNumber:aadhaar})
			if (res.data.status) {
				this.spinaadhaar = false
				if (res.data.isPhone) {
					this.noLinked = true
					this.noNotLinked = false       
					this.popToast('booked','Success',res.data.message)
					this.countdown = 30;
					if (this.timerStart) {
						clearInterval(this.timerStart);
					}
					this.timer();
					setTimeout(() => {
						this.resend = true
					},30000)
				}
				else {
					this.spinaadhaar = false
					this.noNotLinked = true
					this.noLinked = false
					this.popToast('booked','Success','We could not find a mobile number registered with your Aadhaar. Please upload your Aadhaar manually.')
				}
			}
			else {
				this.spinaadhaar = false
				if(res.data.message == 'Backend Timed Out. Try Again.') {
					this.noNotLinked = true
					this.popToast('failed','Failed', 'We are not able to validate your Aadhaar right now. Please upload your Aadhaar manually.')
					// if(this.form.sellerCompanyType.id == 6) {
					// 	this.addressDisable = false
					// }
				}else {
					this.noNotLinked = true
					this.popToast('failed','Failed',res.data.message)
				}

				if (res.data.isValid) {
					this.noNotLinked = true
				}

				this.noLinked = false
			}
		}
		catch(e) {
	        // statements
	        this.spinaadhaar = false
	        console.log(e);
	    }
	}
},
async verifyGST(gst) {
	this.spingst = true
	if (gst.length < 15) {
		this.popToast('failed','Failed',"Please enter a Valid GST Number")
		this.spingst = false
	} else if (!(this.form.sellerCompanyType && this.form.sellerCompanyType.id)) {
		this.popToast('failed','Failed',"Please Select a Company Type")
		this.spingst = false
	}
	 else {
		try {
			const res = await this.axios.post('/register/gstVerification',{gstNo:gst})
			if (res.data.status) {
				this.spingst = false
				this.gstVerified = true
				this.popToast('booked','Success',res.data.message);
				if (res.data.address && this.form.sellerCompanyType.id != 6) {
					this.form.address = ''
					this.form.address = res.data.address;
					this.form['pincode'] = res.data.pincode;
					this.form['city'] = res.data.city
					this.form['addressID'] = res.data.addressID
				}
				if(res.data.companyName) {
					this.form.gstCompanyName = res.data.companyName
				}
	          // statement
	      } else {
	      	this.spingst = false
	      	this.gstVerified = null
	      	if(res.data.message == 'Backend Timed Out. Try Again.') {
	      		// this.popToast('failed','Failed', 'We are not able to validate your gst right now.')
				this.gstVerified = true
	      		if(this.form.sellerCompanyType.id != 6) {
	      			this.addressDisable = false
	      		}
	      	}else {
	      		this.popToast('failed','Failed',res.data.message)
	      	}
	      }
	  	} catch(e) {
	        // statements
	        this.spingst = false
	        this.gstVerified = null
	        console.log(e);
	    }
	}
	
},
async verifyPAN(pan) {
	this.spinpan = true
	try {
		const res = await this.axios.post('/register/panVerification',{panNo:pan})
		if (res.data.status) {
			this.spinpan = false
			this.panVerified = true
			this.popToast('booked','Success',res.data.message);
          // statement
      } else {
      	this.spinpan = false
      	this.panVerified = null
      	this.popToast('failed','Failed',res.data.message)
          // statement
      }
  } catch(e) {
        // statements
        this.panVerified = null
        this.spinpan = false
        console.log(e);
    }
},
async verifyCIN(cin) {
	this.spincoi = true
	try {
		if (this.form.coi && this.form.coi.length) {
			this.form.coi = cin.split(/\s/).join('')
		}
		const res = await this.axios.post('/register/cinVerification',{cinNo:this.form.coi})
		if (res.data.status) {
			this.coiVerified = true
			this.popToast('booked','Success',"COI Verified Successfully");
		} else {
			this.popToast('failed','Failed',res.data.message)
		}
	} catch(e) {
	}
	this.spincoi = false
},
openWebcamModal(){
	const constraints = (window.constraints = {
		audio: false,
		video: true
	});


	navigator.mediaDevices
	.getUserMedia(constraints)
	.then(stream => {

		this.$refs.webcam.isOpen = !this.$refs
		.webcam.isOpen;

	})
	.catch(error => {

		this.popToast('failed','Failed',`Please grant access to your camera from browser settings to enable this feature.`)

	});
},
clearImages(type){
	if (type == 'front') {
		this.form.adhaarfrontImg = null
		return
	} else if (type == 'back') {
		this.form.adhaarbackImg = null
		return
	} else {
		this.form.faceImg = null
	}
},
async selectImages(image,type) {
	if(image != null) {
		let total  = Math.round((image.size / 1024))
		if (type == 'front') {
			if(total > 5120) {
				this.popToast('failed','Failed','Files Size exceeds 5MB!! Please Try Again')
				this.form.adhaarfrontImg = null
				return
			}
			if (!(image.name.split('.').pop().toLowerCase().includes('png') || image.name.split('.').pop().toLowerCase().includes('jpeg') || image.name.split('.').pop().toLowerCase().includes('jpg'))) {
				this.popToast('failed','Failed','Only PNG, JPG or JPEG file format is supported')
				this.form.adhaarfrontImg = null
				return
			}
		} else if(type == 'back') {
			if(total > 5120) {
				this.popToast('failed','Failed','Files Size exceeds 5MB!! Please Try Again')
				this.form.adhaarbackImg = null
				return
			}
			if (!(image.name.split('.').pop().toLowerCase().includes('png') || image.name.split('.').pop().toLowerCase().includes('jpeg') || image.name.split('.').pop().toLowerCase().includes('jpg'))) {
				this.popToast('failed','Failed','Only PNG, JPG or JPEG file format is supported')
				this.form.adhaarbackImg = null
				return
			}

		} else {
			if(total > 5120) {
				this.popToast('failed','Failed','Files Size exceeds 5MB!! Please Try Again')
				this.form.faceImg = null
				return
			} else {
				if (!(image.name.split('.').pop().toLowerCase().includes('png') || image.name.split('.').pop().toLowerCase().includes('jpeg') || image.name.split('.').pop().toLowerCase().includes('jpg'))) {
						this.popToast('failed','Failed','Only PNG, JPG or JPEG file format is supported')
						this.form.faceImg = null
						return
					} 
					else {

						const obj = {}
						obj['photo'] = image
						obj['sentFrom'] = 'select';
						let fd = new FormData();
						for (const key of Object.keys(obj)) {
							fd.append(key, obj[key]);
						}

						try {
							const res = await this.axios.post('/register/photoVerification',fd)
							if (res.data.status) {
								// this.popToast('booked','Success',res.data.message)
							} else {
							}
						} catch(e) {
		              // statements
		              console.error(e);
		          }

		      }
			}
			
  }
}
if (this.form.adhaarfrontImg && this.form.adhaarbackImg && this.form.adhaarfrontImg.name == this.form.adhaarbackImg.name && type == 'back') {
	this.popToast('failed','Failed',`Aadhaar’s Front and Back cannot be the same. Please upload appropriate images.`)
	this.form.adhaarbackImg = null
}
if (this.form.adhaarfrontImg && this.form.adhaarbackImg && this.form.adhaarbackImg.name == this.form.adhaarfrontImg.name && type == 'front') {
	this.popToast('failed','Failed',`Aadhaar’s Front and Back cannot be the same. Please upload appropriate images.`)
	this.form.adhaarfrontImg = null
}
},
enterGST(value){
	if (value.length > 0) {
		value = value.toUpperCase();
		this.form.gstno = value
		this.form.panNo = String(value).substring(2, 12);
	}
},
popInput(value) {
	this.$refs[value].$el.click();
},
clearIdFile(value) {
	this.$refs[value].reset();
	this.url = null;
},
openInfoModal: function () {
	this.$refs.infoModal.isOpen = !this.$refs.infoModal.isOpen;
	this.bottomOrderMenuToggle = true;
},
detectWebcam(callback) {
	let md = navigator.mediaDevices;
	if (!md || !md.enumerateDevices) return callback(false);
	md.enumerateDevices().then(devices => {
		callback(devices.some(device => 'videoinput' === device.kind));
	})
}
}
};
</script>

<style lang="scss" scoped>
.frontClass {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 9rem;
}
/deep/ .spinner-border{
	width: 1rem;
	height: 1rem;
}
.sellerAddressLine {
	height: 8rem !important;
}
/deep/ a:hover, a:focus, a:active {
	color: #006eff !important;
}
.selectImage {
	width: 1.5rem;
	height: 1.5rem;
}
.html {
	scroll-behavior: smooth !important;
}
.errorText{
	height: 2.5rem;
	width: 16rem;
	background-color: #ffa6a6;
	color: red;
	border: 1px #ffa6a6;
	border-radius: 5px;
}

.errorText span {
	margin: 0px;
	position: relative;
	top: 30%;
	left: 8%;   
	transform: translate(0,-50%);
	text-align: center;
}

.input-group-addon {
	overflow: auto;
	border: 1px solid rgb(207, 207, 207);
	padding: 10px;
	margin-top: 0px;
	max-height: 13rem;
}
.input-group-text {
	height: 2.75rem !important;
}
#company-pan {
	padding: 1.4rem 1rem 2.3rem 1rem;
}
.cin-text {
	right: 0.5rem;
}
.editImage {
	height: 1rem;
	width: 1rem;
	cursor: pointer;
}
.verified {
	color :#26c341 !important;
	background: white !important;

	&:hover,&:active,&:focus {
		color :white !important;
		background: #26c341 !important;
	}
}
</style>