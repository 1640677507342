var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid pt-50 pt-md-80 pb-80 pb-md-0" },
    [
      _c(
        "div",
        { staticClass: "kycForm" },
        [
          _c("b-form", { on: { submit: _vm.kycformSubmit } }, [
            _c("div", { staticClass: "card card__kyc mt-24 mb-20" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("span", { staticClass: "d-block fs18 lh28 fw500 mb-14" }, [
                    _vm._v(" Company Information ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "mw528" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "company-type-field",
                            "label-for": "company-type"
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              id: "options.id",
                              placeholder: "Company Type (required)",
                              options: _vm.options,
                              searchable: false,
                              "track-by": "company",
                              label: "company",
                              "allow-empty": false,
                              "show-labels": false
                            },
                            model: {
                              value: _vm.form.sellerCompanyType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sellerCompanyType", $$v)
                              },
                              expression: "form.sellerCompanyType"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "mw528" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "company-name-field",
                            "label-for": "company-name",
                            description:
                              _vm.form.sellerCompanyType.id == 6
                                ? "NOTE : Invoice will be raised with this Legal Name"
                                : "NOTE : Invoice will be raised with this Legal Name. Legal Name should match with GST Registered Trade Name"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              id: "company-name",
                              placeholder: "Legal Name (required)"
                            },
                            nativeOn: {
                              blur: function($event) {
                                return _vm.disallowSpace(
                                  _vm.form.sellerCompanyName,
                                  "company"
                                )
                              }
                            },
                            model: {
                              value: _vm.form.sellerCompanyName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sellerCompanyName", $$v)
                              },
                              expression: "form.sellerCompanyName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "span",
                    { staticClass: "d-block fs18 lh28 fw500 pt-24 mb-14" },
                    [_vm._v(" Contact Information ")]
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "mw528" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "contact1-field",
                            "label-for": "contact1"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              id: "contact1",
                              formatter: _vm.maxten,
                              maxlength: "10",
                              minlength: "10",
                              type: "text",
                              pattern: "[6-9]{1}[0-9]{9}",
                              oninvalid:
                                "this.setCustomValidity('Enter a Valid Mobile Number')",
                              oninput: "this.setCustomValidity('')",
                              placeholder: "Primary Contact Number (required)",
                              state: _vm.isNumber
                            },
                            on: {
                              keyup: function($event) {
                                return _vm.numberValidation(
                                  $event.target.value,
                                  "contact1"
                                )
                              }
                            },
                            model: {
                              value: _vm.form.sellerContactNo,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sellerContactNo", $$v)
                              },
                              expression: "form.sellerContactNo"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "mw528" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "contact2-field",
                            "label-for": "contact2"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "contact2",
                              formatter: _vm.maxten,
                              type: "number",
                              placeholder:
                                "Secondary Contact Number (optional)",
                              state: _vm.isNumber2
                            },
                            on: {
                              keyup: function($event) {
                                return _vm.numberValidation(
                                  $event.target.value,
                                  "contact2"
                                )
                              }
                            },
                            model: {
                              value: _vm.form.sellerContactNo2,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sellerContactNo2", $$v)
                              },
                              expression: "form.sellerContactNo2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "row pt-20 pt-md-40" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "mw528" }, [
                    _c(
                      "span",
                      { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                      [
                        _vm._v(" Identity Verification "),
                        _vm.aadhaarVerified ||
                        _vm.voterverified ||
                        _vm.dlverified
                          ? _c("img", {
                              staticClass: "ml-10 my-auto editImage",
                              attrs: { src: require("@/assets/svg/edit.svg") },
                              on: {
                                click: function($event) {
                                  return _vm.editAadhaar()
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { "label-for": "type" } },
                            [
                              _c("multiselect", {
                                staticClass: "mb-17",
                                attrs: {
                                  id: "type",
                                  disabled:
                                    _vm.aadhaarVerified ||
                                    _vm.voterverified ||
                                    _vm.dlverified,
                                  title: "Mode",
                                  options: _vm.computedidTypes,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": false
                                },
                                model: {
                                  value: _vm.idType,
                                  callback: function($$v) {
                                    _vm.idType = $$v
                                  },
                                  expression: "idType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.idType == "Aadhaar"
                        ? _c(
                            "div",
                            { staticClass: "col-md-8" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "company-gstin-field",
                                    "label-for": "company-aadhaar"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      type: "text",
                                      pattern: "[0-9]{12}",
                                      oninvalid:
                                        "this.setCustomValidity('Enter valid AADHAAR number.')",
                                      oninput: "this.setCustomValidity('')",
                                      formatter: _vm.numbers,
                                      maxlength: "12",
                                      minlength: "12",
                                      disabled: _vm.aadhaarVerified,
                                      id: "company-aadhaar",
                                      placeholder: "AADHAAR Number (required)"
                                    },
                                    model: {
                                      value: _vm.form.adhaar,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "adhaar", $$v)
                                      },
                                      expression: "form.adhaar"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "input-group-append" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "input-group-text",
                                          style: _vm.aadhaarVerified
                                            ? "background-color:#e3e7ee"
                                            : ""
                                        },
                                        [
                                          _vm.aadhaarVerified
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass: "verified",
                                                  attrs: {
                                                    disabled: "",
                                                    size: "xxs"
                                                  }
                                                },
                                                [_vm._v("Verified")]
                                              )
                                            : _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    size: "xxs",
                                                    variant: "tertiary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.verifyAadhaar(
                                                        _vm.form.adhaar
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm.spinaadhaar
                                                    ? _c("b-spinner")
                                                    : _c("span", [
                                                        _vm._v("Verify ")
                                                      ])
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.idType == "Voter ID"
                        ? _c(
                            "div",
                            { staticClass: "col-md-8" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "company-gstin-field",
                                    "label-for": "company-aadhaar"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      type: "text",
                                      pattern: "[a-zA-Z0-9]",
                                      oninvalid:
                                        "this.setCustomValidity('Enter valid Voter Id number.')",
                                      oninput: "this.setCustomValidity('')",
                                      maxlength: "10",
                                      minlength: "10",
                                      disabled: _vm.voterverified,
                                      id: "company-aadhaar",
                                      placeholder: "Voter ID Number (required)"
                                    },
                                    model: {
                                      value: _vm.form.voterId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "voterId", $$v)
                                      },
                                      expression: "form.voterId"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "input-group-append" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "input-group-text",
                                          style: _vm.voterverified
                                            ? "background-color:#e3e7ee"
                                            : ""
                                        },
                                        [
                                          _vm.voterverified
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass: "verified",
                                                  attrs: {
                                                    disabled: "",
                                                    size: "xxs"
                                                  }
                                                },
                                                [_vm._v("Verified")]
                                              )
                                            : _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    size: "xxs",
                                                    variant: "tertiary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.verifyVoter(
                                                        _vm.form.voterId
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm.spinaadhaar
                                                    ? _c("b-spinner")
                                                    : _c("span", [
                                                        _vm._v("Verify ")
                                                      ])
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.idType == "Driving License"
                        ? _c("div", { staticClass: "col-md-8 row pr-0" }, [
                            !_vm.dlverified
                              ? _c(
                                  "div",
                                  { staticClass: "col-md-12 pr-0" },
                                  [
                                    _c("date-picker", {
                                      staticClass: "mb-17",
                                      attrs: {
                                        "prefix-class": "xmx",
                                        id: "dp",
                                        title: "Date of Birth",
                                        "value-type": "format",
                                        format: "YYYY-MM-DD",
                                        "disabled-date": function(date) {
                                          return date > new Date()
                                        },
                                        placeholder: "Date of Birth",
                                        editable: false
                                      },
                                      model: {
                                        value: _vm.dobDl,
                                        callback: function($$v) {
                                          _vm.dobDl = $$v
                                        },
                                        expression: "dobDl"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "company-gstin-field",
                                      "label-for": "company-aadhaar"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        required: "",
                                        type: "text",
                                        maxlength: "16",
                                        minlength: "10",
                                        disabled: _vm.dlverified,
                                        id: "company-aadhaar",
                                        placeholder:
                                          "Driving License Number (required) "
                                      },
                                      model: {
                                        value: _vm.form.drivinglicense,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "drivinglicense",
                                            $$v
                                          )
                                        },
                                        expression: "form.drivinglicense"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "input-group-text",
                                            style: _vm.dlverified
                                              ? "background-color:#e3e7ee"
                                              : ""
                                          },
                                          [
                                            _vm.dlverified
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "verified",
                                                    attrs: {
                                                      disabled: "",
                                                      size: "xxs"
                                                    }
                                                  },
                                                  [_vm._v("Verified")]
                                                )
                                              : _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      size: "xxs",
                                                      variant: "tertiary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.verifyDL(
                                                          _vm.form
                                                            .drivinglicense,
                                                          _vm.dobDl
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm.spinaadhaar
                                                      ? _c("b-spinner")
                                                      : _c("span", [
                                                          _vm._v("Verify ")
                                                        ])
                                                  ],
                                                  1
                                                )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm.noLinked
                      ? _c("div", { staticClass: "d-flex" }, [
                          _c("div", { staticClass: "col-md-4" }),
                          _c(
                            "div",
                            {
                              staticClass: "col-md-10 row",
                              staticStyle: { "max-width": "75.33333%" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-4 pt-8" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "otp-field",
                                        "label-for": "otp"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          id: "otp",
                                          placeholder: "Enter OTP",
                                          autofocus: "",
                                          required: "",
                                          formatter: _vm.maxsix
                                        },
                                        model: {
                                          value: _vm.otp,
                                          callback: function($$v) {
                                            _vm.otp = $$v
                                          },
                                          expression: "otp"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "pt-5 ml-5" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-10",
                                      attrs: { variant: "primary", size: "lg" },
                                      on: {
                                        click: function($event) {
                                          return _vm.verifyOtp(_vm.otp)
                                        }
                                      }
                                    },
                                    [
                                      _vm.spinotp
                                        ? _c("b-spinner")
                                        : _c("span", [
                                            _vm._v(_vm._s(_vm.btns.verify))
                                          ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "pt-5" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-5",
                                      attrs: {
                                        variant: "tertiary",
                                        disabled: !_vm.resend,
                                        size: "lg"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.resendOtp(_vm.form.adhaar)
                                        }
                                      }
                                    },
                                    [
                                      _vm.spinresend
                                        ? _c("b-spinner")
                                        : _c("span", [_vm._v("Resend OTP ")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.countdown > 0
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-12 mt-10 ml-5" },
                                    [
                                      _c("p", { staticClass: "fs16" }, [
                                        _vm._v(
                                          "You can send an otp again in " +
                                            _vm._s(_vm.countdown) +
                                            " " +
                                            _vm._s(
                                              _vm.countdown < 10
                                                ? "second"
                                                : "seconds"
                                            )
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "col-md-12 mt-10 ml-5" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "fs12 text-gray-600" },
                                    [
                                      _vm._v(
                                        "By entering OTP, I hereby give my voluntary consent for authenticating myself through Aadhaar based Authentication system as permitted under applicable laws. "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-12 pt-20 pt-md-20 ml-5"
                                },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      staticClass: "fs14",
                                      on: { click: _vm.showUpload }
                                    },
                                    [_vm._v("Upload Aadhaar Manually")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "col-md-4" }),
                      _vm.noNotLinked
                        ? _c(
                            "div",
                            {
                              staticClass: "col-md-10 row",
                              staticStyle: { "max-width": "76.33333%" }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "d-block fs14 ml-5 lh20 pt-5 pb-10 text-gray-600"
                                },
                                [
                                  _vm._v(
                                    " Max file size per document is 5MB. Supported formats are JPG, PNG, JPEG. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-12 pt-5 d-flex justify-content-center"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("b-form-file", {
                                        ref: "aadhaar-front-input",
                                        staticClass: "d-none",
                                        attrs: {
                                          plain: "",
                                          accept: ".jpeg, .jpg, .png"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.selectImages(
                                              _vm.form.adhaarfrontImg,
                                              "front"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.form.adhaarfrontImg,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "adhaarfrontImg",
                                              $$v
                                            )
                                          },
                                          expression: "form.adhaarfrontImg"
                                        }
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "w-sm-100" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "w-sm-100 mr-0 mr-md-8",
                                              attrs: {
                                                variant: "primary",
                                                title: _vm.form.adhaarfrontImg
                                                  ? _vm.form.adhaarfrontImg.name
                                                  : ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.popInput(
                                                    "aadhaar-front-input"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "mr-5 selectImage",
                                                attrs: {
                                                  src: require("@/assets/svg/image.svg")
                                                }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.form.adhaarfrontImg
                                                      ? _vm.imageEllipsize(
                                                          _vm.form
                                                            .adhaarfrontImg.name
                                                        )
                                                      : "Select Front Side"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("b-form-file", {
                                        ref: "aadhaar-back-input",
                                        staticClass: "d-none",
                                        attrs: {
                                          plain: "",
                                          accept: ".jpeg, .jpg, .png"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.selectImages(
                                              _vm.form.adhaarbackImg,
                                              "back"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.form.adhaarbackImg,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "adhaarbackImg",
                                              $$v
                                            )
                                          },
                                          expression: "form.adhaarbackImg"
                                        }
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "w-sm-100" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "w-sm-100 mr-0 mr-md-8",
                                              attrs: {
                                                variant: "primary",
                                                title: _vm.form.adhaarbackImg
                                                  ? _vm.form.adhaarbackImg.name
                                                  : ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.popInput(
                                                    "aadhaar-back-input"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "mr-5 selectImage",
                                                attrs: {
                                                  src: require("@/assets/svg/image.svg")
                                                }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.form.adhaarbackImg
                                                      ? _vm.imageEllipsize(
                                                          _vm.form.adhaarbackImg
                                                            .name
                                                        )
                                                      : "Select Back Side"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm.form.adhaarfrontImg != null &&
                              _vm.form.adhaarbackImg != null
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "col-md-12 ml-10 mt-10 row"
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "w-sm-100 mt-8 mt-md-0",
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.submitOCR(
                                                _vm.form.adhaarfrontImg,
                                                _vm.form.adhaarbackImg,
                                                _vm.form.adhaar
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm.spinOCR
                                            ? _c("b-spinner")
                                            : _c("span", [_vm._v("Upload")])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "mw528" }, [
                    _c(
                      "span",
                      { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                      [
                        _vm._v(" GSTIN "),
                        _vm.gstVerified
                          ? _c("img", {
                              staticClass: "ml-10 my-auto editImage",
                              attrs: { src: require("@/assets/svg/edit.svg") },
                              on: {
                                click: function($event) {
                                  _vm.gstVerified = null
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "company-gstin-field",
                                "label-for": "company-gstin"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required:
                                    _vm.form.sellerCompanyType.id == 6
                                      ? false
                                      : true,
                                  type: "text",
                                  maxlength: "15",
                                  id: "company-gstin",
                                  disabled: _vm.gstVerified,
                                  placeholder:
                                    _vm.form.sellerCompanyType.id == 6
                                      ? "Company's GSTIN (optional)"
                                      : "Company's GSTIN (required)"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.enterGST(_vm.form.gstno)
                                  }
                                },
                                model: {
                                  value: _vm.form.gstno,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "gstno", $$v)
                                  },
                                  expression: "form.gstno"
                                }
                              }),
                              _c("div", { staticClass: "input-group-append" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "input-group-text",
                                    style: _vm.gstVerified
                                      ? "background-color:#e3e7ee"
                                      : ""
                                  },
                                  [
                                    !_vm.gstVerified
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              size: "xxs",
                                              variant: "tertiary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.verifyGST(
                                                  _vm.form.gstno
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm.spingst
                                              ? _c("b-spinner")
                                              : _c("span", [_vm._v("Verify")])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    this.form.sellerCompanyType &&
                    this.form.sellerCompanyType.id == 6 &&
                    this.form.gstno.length
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "p",
                              {
                                staticClass: "d-block fs14 ml-5 lh20 text-red",
                                staticStyle: { "font-weight": "600" }
                              },
                              [
                                _vm._v(
                                  " NOTE : Kindly change your Company Type to Sole Proprietorship/Firm "
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _c("div", { staticClass: "row pt-20 pt-md-40" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "mw528" }, [
                    _c(
                      "span",
                      { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                      [
                        _vm._v(" Billing Address "),
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.right",
                              modifiers: { hover: true, right: true }
                            }
                          ],
                          staticClass:
                            "icon-disclaimer text-gray-400 fs12 ml-10",
                          attrs: {
                            title:
                              "You can add your Pickup and Billing Address later"
                          }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "company-tan-field",
                                label: "",
                                "label-for": "company-tan",
                                description:
                                  _vm.form.sellerCompanyType.id == 6 &&
                                  this.idType == "Aadhaar"
                                    ? "This would be automatically captured by your Aadhaar"
                                    : _vm.form.sellerCompanyType.id == 6 &&
                                      this.idType == "Driving License"
                                    ? "This would be automatically captured by your Driving License"
                                    : "This would be automatically captured by your GSTIN"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  required: "",
                                  id: "company-billing",
                                  placeholder: "Your Billing Address",
                                  rows: "1",
                                  disabled: _vm.addressDisable,
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.form.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "address", $$v)
                                  },
                                  expression: "form.address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    !_vm.addressDisable
                      ? _c("div", { staticClass: "row col" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  id: "company-city",
                                  type: "text",
                                  placeholder: "City (required)"
                                },
                                model: {
                                  value: _vm.form.city,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "city", $$v)
                                  },
                                  expression: "form.city"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  id: "company-pin",
                                  type: "text",
                                  placeholder: "Pincode (required)"
                                },
                                model: {
                                  value: _vm.form.pincode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "pincode", $$v)
                                  },
                                  expression: "form.pincode"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "mw528" }, [
                    _c(
                      "span",
                      { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                      [
                        _vm._v(" PAN "),
                        _vm.form.sellerCompanyType.id == 6 && _vm.panVerified
                          ? _c("img", {
                              staticClass: "ml-10 my-auto editImage",
                              attrs: { src: require("@/assets/svg/edit.svg") },
                              on: {
                                click: function($event) {
                                  _vm.panVerified = null
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "company-pan-field",
                                "label-for": "company-pan"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  disabled:
                                    _vm.form.sellerCompanyType.id == 6 &&
                                    _vm.panVerified == null
                                      ? false
                                      : _vm.panVerified == true
                                      ? true
                                      : true,
                                  required: "",
                                  maxlength: "10",
                                  id: "company-pan",
                                  type: "text",
                                  placeholder: "Company's PAN (required)"
                                },
                                model: {
                                  value: _vm.form.panNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "panNo", $$v)
                                  },
                                  expression: "form.panNo"
                                }
                              }),
                              _c("div", { staticClass: "input-group-append" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "input-group-text cin-text",
                                    style: (_vm.form.sellerCompanyType.id ==
                                      6 && _vm.panVerified == null
                                    ? false
                                    : _vm.panVerified == true
                                    ? true
                                    : true)
                                      ? "background-color:#e3e7ee"
                                      : ""
                                  },
                                  [
                                    _vm.form.sellerCompanyType.id == 6 &&
                                    _vm.panVerified == null
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              size: "xxs",
                                              variant: "tertiary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.verifyPAN(
                                                  _vm.form.panNo
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm.spinpan
                                              ? _c("b-spinner")
                                              : _c("span", [_vm._v("Verify")])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row pt-20 pt-md-40 pb-20" }, [
                _vm.form.sellerCompanyType.id == 1 ||
                _vm.form.sellerCompanyType.id == 3 ||
                _vm.form.sellerCompanyType.id == 5 ||
                _vm.form.sellerCompanyType.id == 8
                  ? _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "mw528" }, [
                        _c(
                          "span",
                          { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                          [
                            _vm._v(" Certificate of Incorporation "),
                            _vm.coiVerified
                              ? _c("img", {
                                  staticClass: "ml-10 my-auto editImage",
                                  attrs: {
                                    src: require("@/assets/svg/edit.svg")
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.coiVerified = null
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "company-tan-field",
                                    label: "",
                                    "label-for": "company-tan"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      maxlength: "21",
                                      minlength: "7",
                                      placeholder: "Company's CIN (required)",
                                      pattern: "[a-zA-Z0-9]+",
                                      oninvalid:
                                        "this.setCustomValidity('Please Enter a Valid COI Number')",
                                      oninput: "this.setCustomValidity('')",
                                      disabled: _vm.coiVerified
                                    },
                                    model: {
                                      value: _vm.form.coi,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "coi", $$v)
                                      },
                                      expression: "form.coi"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "input-group-append" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input-group-text cin-text",
                                          style: _vm.coiVerified
                                            ? "background-color:#e3e7ee"
                                            : ""
                                        },
                                        [
                                          !_vm.coiVerified
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    size: "xxs",
                                                    variant: "tertiary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.verifyCIN(
                                                        _vm.form.coi
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm.spincoi
                                                    ? _c("b-spinner")
                                                    : _c("span", [
                                                        _vm._v("Verify")
                                                      ])
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "mw528" },
                    [
                      _c(
                        "span",
                        { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                        [_vm._v(" Full Name ")]
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "seller-name",
                            "label-for": "seller-name-field"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              id: "seller-name-field",
                              oninvalid:
                                "this.setCustomValidity('Please Enter full name')",
                              oninput: "this.setCustomValidity('')",
                              pattern: ".* .*",
                              placeholder: "Your complete name (required)",
                              trim: "",
                              maxlength: "50",
                              type: "text"
                            },
                            nativeOn: {
                              blur: function($event) {
                                return _vm.disallowSpace(_vm.form.name, "name")
                              }
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "pt-10 pb-20" }, [
                _c(
                  "div",
                  { staticClass: "ml-0 fs16" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        model: {
                          value: _vm.form.agreement,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "agreement", $$v)
                          },
                          expression: "form.agreement"
                        }
                      },
                      [
                        _c("strong", [
                          _c(
                            "span",
                            [
                              _vm._v(
                                "By clicking Submit, you agree to Shyplite's "
                              ),
                              _c(
                                "b-link",
                                {
                                  staticClass: "ml-0 fs16",
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.agreementUrl
                                  }
                                },
                                [_vm._v("Agreement")]
                              ),
                              _vm._v(", "),
                              _c(
                                "b-link",
                                {
                                  staticClass: "ml-0 fs16",
                                  attrs: {
                                    target: "_blank",
                                    href: "https://shyplite.com/tou"
                                  }
                                },
                                [_vm._v("Terms of Service")]
                              ),
                              _vm._v(" and "),
                              _c(
                                "b-link",
                                {
                                  staticClass: "ml-0 fs16",
                                  attrs: {
                                    target: "_blank",
                                    href: "https://shyplite.com/privacy"
                                  }
                                },
                                [_vm._v("Privacy Policy")]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "d-flex pb-20 pt-md-20" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        size: "lg",
                        disabled: !_vm.form.agreement,
                        type: "submit"
                      }
                    },
                    [
                      _vm.submitting
                        ? _c("b-spinner")
                        : _c("span", [_vm._v("Submit")])
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-16",
                      attrs: {
                        to: "/dashboard",
                        variant: "secondary",
                        size: "lg"
                      }
                    },
                    [_vm._v(" Skip ")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c("KYCInfoModal", { ref: "infoModal" }),
      _c("Webcam", { ref: "webcam", on: { openModal: _vm.openWebcamModal } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }